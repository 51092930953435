import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyDxVcKYtx9afXfebC237DLI4BM3lxWclKY",
  authDomain: "thenannycare-2020.firebaseapp.com",
  projectId: "thenannycare-2020",
  storageBucket: "thenannycare-2020.appspot.com",
  messagingSenderId: "381131629270",
  appId: "1:381131629270:web:0167ffcf00b42c4d159f77"
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
