import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";

const Loading = () => <div>Loading...</div>;

const Home = Loadable({
	loader: () => import("./Containers/Home"),
	loading: Loading
});
const Aboutus = Loadable({
	loader: () => import("./Containers/Aboutus"),
	loading: Loading
});
const Contactus = Loadable({
	loader: () => import("./Containers/Contactus"),
	loading: Loading
});
const Nannies = Loadable({
	loader: () => import("./Containers/Nannies"),
	loading: Loading
});
const Families = Loadable({
	loader: () => import("./Containers/Families"),
	loading: Loading
});

const Routes = () => (
	<BrowserRouter>
		<Switch>
			<Route exact path="/" component={Home} />
			<Route exact path="/aboutus" component={Aboutus} />
			<Route exact path="/contactus" component={Contactus} />
			<Route exact path="/nannies" component={Nannies} />
			<Route exact path="/families" component={Families} />
		</Switch>
	</BrowserRouter>
);

export default Routes;
